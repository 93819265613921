import React from "react";
import { graphql } from "gatsby";
import Helmet from "react-helmet";
import ReactMarkdown from "react-markdown";

import Layout from "../components/Layout";



export const ContactConfirmationPageTemplate = ({ page }) => {
  return (
    <div className="section gradient-section contact-confirmation flex-row alitems-stretch">
      <div className="content-wrapper white-text flex-column justc-center alitems-center">
        <img src="/img/contact-confirmation.svg" alt="illu confirmation contact" />
        <div className="title-4 section-title white-text mgt-m mgb-s">{page.frontmatter.title}</div>
          <ReactMarkdown children={page.frontmatter.text} />
        </div>
    </div>
  )
}


const ContactConfirmation = ({data}) => {
  const { markdownRemark: page, footerData, navbarData } = data;
  return (
    <Layout footerData={footerData} navbarData={navbarData}>
      <Helmet>
        <title>Confirmation</title>
      </Helmet>
      <ContactConfirmationPageTemplate page={page}/>
    </Layout>
  )
}

export default ContactConfirmation;


export const contactConfirmationQuery = graphql`
  query ContactConfirmation($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        text
      }
    }
    ...LayoutFragment
  }
`